<template>
  <tr>
    <td class="justify-center px-1">
      <div>
        <v-btn flat outline color="green" @click="config.select(props.item)" class="mx-0">
          <v-icon large outlined>done</v-icon>
        </v-btn>
      </div>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <span style="display: flex; align-items: center;">
        <strong>
          #{{ props.item.id }}
        </strong>
        <v-tooltip v-if="props.item.visibility == 'person'" bottom lazy>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pl-1" color="green">
              person
            </v-icon>
          </template>
          <span>
            Visível apenas para mi
          </span>
        </v-tooltip>
        <v-tooltip v-else bottom lazy>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="pl-1" color="blue">
              public
            </v-icon>
          </template>
          <span>
            Visível para todos
          </span>
        </v-tooltip>
      </span>
    </td>
    <td class="text-xs-left" style="max-width: 250px; padding: 0 10px !important;">
      <strong>
        {{ props.item.name }}
      </strong>
    </td>
    <td class="text-xs-left" style="max-width: 150px; padding: 0 10px !important;">
      <v-chip v-if="props.item.category" small :color="props.item.category.color">
        <strong
          v-text="props.item.category.name"
          :style="{
            color: $WiColorContrast(props.item.category.color)
          }"
        ></strong>
      </v-chip>
      <strong v-else>- - -</strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <v-dialog v-model="messagePreviewDialog" max-width="500px" scrollable>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <div style="cursor: pointer;" class="message-template">
              <span v-if="props.item.text_html_with_variables">
                <span v-html="limitMessageTemplate(props.item.text_html_with_variables)"></span>
              </span>
              <span v-else-if="props.item.text_with_variables">
                {{ props.item.text_with_variables | stringlength(80) }}
              </span>
              <span v-else-if="props.item.text_html">
                <span v-html="limitMessageTemplate(props.item.text_html)"></span>
              </span>
              <span v-else>
                {{ props.item.text | stringlength(80) }}
              </span>
            </div>
            <span style="color: gray; padding-left: 5px;">
              <span>Clique para </span>
              <v-icon small>edit</v-icon>
              <strong class="pl-1">Editar</strong> ou ver a mensagem completa
            </span>
          </div>
        </template>
        <v-card style="max-height: 800px;">
          <v-toolbar dense>
            <v-toolbar-title>
              <v-icon>message</v-icon>
              <span> Mensagem rápida</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="messagePreviewDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div v-if="props.item.visibility == 'person'" bottom lazy>
              <v-icon class="pl-1" color="green">
                person
              </v-icon>
              <span>
                Visível apenas para mim
              </span>
            </div>
            <div v-else bottom lazy>
              <v-icon class="pl-1" color="blue">
                public
              </v-icon>
              <span>
                Visível para todos
              </span>
            </div>
            <div class="message-template mt-3">
              <span v-if="props.item.text_html_with_variables">
                <span v-html="props.item.text_html_with_variables"></span>
              </span>
              <span v-else-if="props.item.text_with_variables">
                {{ props.item.text_with_variables }}
              </span>
              <span v-else-if="props.item.text_html">
                <span v-html="props.item.text_html"></span>
              </span>
              <span v-else>
                {{ props.item.text }}
              </span>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <db-delete 
              v-prms="{'id': getIdPermission('delete', config.config), mode: 'disabled'}" 
              class="mx-0 wrapper" 
              v-on:action="config.refresh" 
              :store="config.store" 
              :id="props.item.id"
            ></db-delete>
            <v-btn
              color="blue" class="white--text" outline
              @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: props.item})" 
              v-prms="{'id': getIdPermission('update', config.config), mode: 'disabled'}" 
            >
              <v-icon>edit</v-icon>
              <span class="pl-2"> Editar</span>
            </v-btn>
            <v-btn
              color="green" class="white--text" outline
              @click="config.select(props.item)"
            >
              <v-icon>done</v-icon>
              <span class="pl-2"> Selecionar para enviar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'TalkMessageTemplates',
    data: () => ({
      messagePreviewDialog: false
    }),
    methods: {
      limitMessageTemplate: function (messageText) {
        messageText = messageText.replaceAll('<br>', ' ')
        messageText = messageText.replaceAll('<br >', ' ')
        messageText = messageText.replaceAll(/<br>/g, ' ')
        messageText = messageText.replaceAll(/<br >/g, ' ')
        messageText = messageText.replaceAll('<br />', ' ')
        messageText = messageText.replaceAll('<br/>', ' ')
        messageText = messageText.replaceAll(/<br\/>/g, ' ')
        messageText = messageText.replaceAll(/<br \/>/g, ' ')
        return this.$options.filters.stringlength(messageText, 70)
      },
      getIdPermission: function (action, config) {
        if (config && config.id) {
          return config.id[action] || config.id
        }
        return null
      }
    },
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .talk-toolbar__title-text .v-list__tile {
    padding: 0 !important;
  }
  .message-template {
    margin: 5px 0 0px 0;
    padding: 5px 10px;
    background: #D4FCC8 !important;
    border-radius: 15px 0px 15px 15px;
  }
</style>